/* HOME PAGE STYLES */

@font-face {
  font-family: 'Suse';
  src: url('/public/fonts/SUSE-VariableFont_wght.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

h3, h4 {
    font-family: 'SUSE';
}
 
/* Main container div */
.homeContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  
  /* Main container div for body copy */
  .mainContent {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    width: 100%;
    max-width: 1400px; 
    padding: 0 1rem; /* Less padding for mobile */
  }
  /* 
  Main DIv Portfolio Items */

  /* H Heading */
  
  .homeHeading{
    margin-top: 4%;
  }

  /* Import Image for pg 1 headers */
  
  .headerWithThumbnail {
    display: flex;
    align-items: center;
  }
  
  .headerWithThumbnail h4 {
    margin-right: 10px;
  }
  
  .headerWithThumbnail .thumbnail {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 4px;
    margin-right: 1%;
  }

  .portfolioItems {
  margin-bottom: 2%;
   } 
  
  
  .portfolioItems h3 {
    margin-top: 2%;
    /* align-items: center; */
  }
  
  .portfolioItems p {
    margin: 0.5rem 0 0;
    text-align: justify;
    padding-left: 1%;
    padding-right: 1%;
  }
  
  .portfolioItem ul {
    align-self: start;
  }
  
  /* Bullets*/
  .portfolioItem ul {
    list-style-type: disc; 
    margin-left: 20px; 
    padding-left: 0; 
  } 

  /* Paragraph styles */

  .paragraphStyle {
    padding-bottom: 1%;
  }
