/* CSS MODULE FOR CONTACT */

@font-face {
  font-family: 'Suse';
  src: url('/public/fonts/SUSE-VariableFont_wght.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

h2, h3, h4 {
    font-family: 'SUSE';
}

.formGroup label, input {
  font-family: 'Suse';  /* Change the font to your preferred one */
}

.contactContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

/* Headers */

.contactHeader h2 {
  margin-top: 2rem;
  font-size: 1.8rem;
  margin-bottom: 0.5rem;
  text-align: center;
}

/* Contact Form */

.contactForm {
  display: flex;
  justify-content: center;
}

.form {
  background: #fbfbfb;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 600px;
}

/* Mobile-first single column layout for form rows */
.row {
  display: grid;
  grid-template-columns: 1fr;
  gap: 15px;
  margin-bottom: 15px;
}

.formGroup label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.formGroup input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.submitButton {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: block;
  margin: 0 auto;
  width: 100%; /* Full width for mobile */
  margin-top: 15px; /* Add spacing for mobile */
}

.submitButton:hover {
  background-color: #0056b3;
}

/* Media Query for Tablet and Larger Devices */
@media (min-width: 768px) {

  .form {
    background: #fbfbfb;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 100%;
  }

  .row {
    grid-template-columns: 1fr 1fr; /* Two columns for larger screens */
  }

  .row:last-child {
    grid-template-columns: 1fr; /* Keep Subject full width */
  }

  .submitButton {
    width: auto; /* Auto width for desktop */
    padding: 10px 40px; /* Larger padding for desktop */
  }
}
