/* CLIENTS/PORTFOLIO STYLES */

/* Base styles for mobile-first design */

.clientsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  

  a {
   text-align: center;
   text-decoration: none;
   cursor: pointer;
  }

  .clientSubHeader {
    margin: 2rem 0;
    text-align: center;
  }
  
  .clientSubHeader h2 {
    font-size: 1.8rem; /* Slightly smaller for mobile */
    margin-bottom: 0.5rem;
  }
  
  .clientSubHeader p {
    font-size: 1rem; /* Slightly smaller for mobile */
    color: #666;
  }
  
  .clientMainContent {
    display: grid;
    grid-template-columns: 1fr; /* Different column sizes */
    gap: 16px; /* This provides equal spacing of 16px */
    padding: 0 1rem; /* Less padding for mobile */
    background-color: #f5f5f5db; /* Example background color */
  }
  
  .clientPortfolioItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f8f9fa;
    padding: 1rem;
    margin: 1rem 0;
    width: 100%;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .clientPortfolioItem  h3 {
    margin-top: 0;
  }
  
  .clientPortfolioItem  p {
    margin: 0.5rem 0 0;
  }
  
  .clientYellowText {
    color: yellow;
  }
  
  
  /* Styles for larger dynamic images */
  .portfolioImage, .portfolioImageMobile {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    border-radius: 8px;
    transition: transform 0.3s;
    cursor: pointer;
    margin-bottom: 1rem; /* Space between image and text */
  }

  .imageHeading {
    font-size: 1.5rem;
  }
  
  .portfolioImage img {
    width: 100%;
    height: auto;
    border-radius: 8px;
    display: block;
  }
  
  .portfolioImageMobile img {
    width: 50%;
    height: auto;
    border-radius: 8px;
    display: block;
  }
  
  
  .portfolioImage:hover, .portfolioImageMobile:hover {
    transform: scale(1.05);
  }

      /* CLIENT PAGE PORTFOLIO 2 COL VIEW */
/* Responsive grid for larger screens */
@media (min-width: 768px) {
    .clientMainContent {
      grid-template-columns: 1fr 1fr; /* Two columns for tablets and larger screens */
      padding: 0 2rem; /* More padding for larger screens */
    }
  }
  
  @media (min-width: 1440px) {
    .clientMainContent {
      grid-template-columns: repeat(4, 1fr); /* Three columns for larger screens */
      gap: 24px; /* Larger gap for bigger screens */
    }
  }
  
  