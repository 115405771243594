/* Mobile-first base styles */

/* GLOBAL STYLES */
/* Import the custom font */
@font-face {
  font-family: 'Orbitron';
  src: url('../public/fonts/Orbitron-VariableFont_wght.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* Orbitron Header Font */
.orbitron-header {
  font-family: 'Orbitron', sans-serif;
}

body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  font-size: .8rem;
  line-height: 1.5;
}

header {
  text-align: center;
  padding: 1rem;
  background-color: #f8f9fa;
}

/* Hero section banner div */
.hero-section {
  width: 100%;
  height: 75vh; /* Adjust the height to cover 3/4 of the viewport */
  background-image: url('/public/media/digitalBlendBlob.webp'); /* Corrected path to match your setup */
  background-size: cover; /* Ensure the image covers the entire section */
  background-position: center; /* Center the image */
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
  position: relative; /* Position relative to position the overlay */
}

/*Overlay section over header banner*/
.hero-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
  z-index: 1; /* Place the overlay above the background but below the content */
}

.hero-content {
  position: relative; /* Position relative to ensure it appears above the overlay */
  z-index: 2; /* Ensures text is above the overlay */
}

.hero-content h1 {
  font-size: 6vw; /* Mobile-first: Larger font size for smaller screens */
  margin: 0;
  color: rgb(209, 209, 209)
  /* padding: 1rem; */
  /* background: rgba(0, 0, 0, 0.5);  */
  /* border-radius: 8px; */
}


/* SUB HEADERS */

.sub-header {
  margin: 2rem 0;
  text-align: center;
}

.yellow-text {
  color: yellow;
}

.sub-header h2 {
  font-size: 1.8rem; /* Slightly smaller for mobile */
  margin-bottom: 0.5rem;
}

.sub-header p {
  font-size: 1rem; /* Slightly smaller for mobile */
  color: #666;
}

main {
  padding: .5rem;
}

/* Footer Styles */
.App-footer {
  display: grid;
  grid-template-columns: 1fr; /* Different column sizes */
  gap: 16px; /* This provides equal spacing of 16px */
  padding: 4%;
  background-color: #f5f5f5db; /* Example background color */
  justify-items: start;
}

.footer-column {
  font-size: .9rem;
  text-align: left;
  margin-left: 2%;
  color: #212121;
}

.footer-column h3 {
  margin-top: 0;
  margin-bottom: 8px; /* Adding some space between the heading and the content */
  font-size: 1.2rem;
}

.footer-column p {
  margin: 4px 0; /* Adding some space between the paragraphs */
}

.footer-logo {
  display: block;
  margin: 0 auto 8px; /* Centering the image and adding bottom margin */
  width: 70px; /* Set width to 50px */
  height: auto; /* Maintain the aspect ratio */
  object-fit: cover; /* Ensure the image covers the dimensions set */
}

.copywrite-copy {
  text-align: center;
 padding: 2%;
  font-size: .7rem;
  background-color: rgb(13, 13, 13);
  color: rgb(213, 213, 213);
  margin-bottom: -1rem;
  /* position: relative; */
}


/*ACCORDIAN SETTINGS*/

.accordion-button {
  background-color: #ebebeb; 
  color: #000; 
}

.accordion-button:not(.collapsed) {
  background-color: #2f2f2f;  
  color: #ededed;
}

.accordion-body {
  background-color: #f9f9f9;;
  color: #333; /* Text color */
}

/* Media Queries */

/* Tablet (Portrait) and larger screens */
@media (min-width: 600px) {

  body {
    font-size: 1rem;
  }

  main {
    max-width: 100%;
    /* margin: 0 auto; */
  }

  .hero-content h1 {
    font-size: 5vw; /* Adjust for tablets */
  }
  
  .sub-header h2 {
    font-size: 2rem; /* Slightly larger for tablets */
  }

  .sub-header p {
    font-size: 1.2rem; /* Adjust for readability */
  }
  
  .main-content {
    padding: 0 2rem; /* Increase padding for larger screens */
  }

  /* CLIENT PAGE PORTFOLIO 2 COL VIEW */
  .client-main-content {
    grid-template-columns: 1fr 1fr ; /* Different column sizes */
  }

  .App-footer {
    grid-template-columns: 1fr 1fr 1fr ; /* Stack columns vertically */
    justify-items: center;
  }
}

/* Desktop (Landscape) and larger screens */
@media (min-width: 1024px) {
  .hero-content h1 {
    font-size: 4vw; /* Smaller relative to viewport width for desktops */
  }
  
  .sub-header h2 {
    font-size: 2.5rem; /* Larger for desktops */
  }

  .sub-header p {
    font-size: 1.4rem; /* Increase font size for readability on larger screens */
  }
  
  .main-content {
    max-width: 1200px; /* Limit the width for larger screens */
    padding: 0 3rem; /* More padding for desktops */
  }

  header, footer {
    padding: 2rem;
  }

  main {
    max-width: auto;
  }

    /* CLIENT PAGE PORTFOLIO 2 COL VIEW */
    .client-main-content {
      grid-template-columns: 1fr 1fr ; /* Different column sizes */
    }

  .App-footer {
    grid-template-columns: 1fr 1fr 1fr ; /* Stack columns vertically */
  }
}

/* Large desktops and wide screens */
@media (min-width: 1440px) {
  .hero-content h1 {
    font-size: 3.5vw; /* Adjust for very large screens */
  }
  
  .sub-header h2 {
    font-size: 3rem; /* Larger for big screens */
  }

  .sub-header p {
    font-size: 1.6rem; /* Readability increase for large screens */
  }
  
  .main-content {
    max-width: 1400px; /* Further limit the width for very large screens */
    padding: 0 4rem; /* More generous padding for wide screens */
  }

  header, footer {
    padding: 2rem;
  }

  main {
    max-width: auto;
  }

}
/* END MEDIA QUERIES*/
